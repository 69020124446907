@mixin transition($time) {
    transition: $time;
    -webkit-transition: $time;
    -moz-transition: $time;
}

$large-desktop: 1440px;
$small-desktop: 1200px;
$desktop: 1025px;
$tablet-ends: 1024px;
$tablet-starts: 768px;
$mobile-ends: 767px;
$mobile-portrait: 480px;

// Responsiveness
@mixin large-desktop {
    @media screen and (min-width: $large-desktop) {
        @content;
    }
}

@mixin small-desktop {
    @media screen and (min-width: $desktop) and (max-width: $small-desktop) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: $tablet-starts) and (max-width: $tablet-ends) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet-ends) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $mobile-ends) {
        @content;
    }
}

@mixin small-mobile {
    @media screen and (max-width: $mobile-portrait) {
        @content;
    }
}

@mixin scrollbar($color) {
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $color;
    }
}
