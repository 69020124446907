// This is global styling SCSS file.

@import 'fonts';
@import 'colors';
@import 'mixins';

* {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
    color: var(--primary-black);
    list-style: none;
}

body {
    font-family: var(--nato-sans);
    font-weight: normal;
    letter-spacing: 0.07px;
}

img {
    display: block;
    max-width: 100%;
    &.full-width {
        width: 100%;
    }
}

.container {
    padding-top: 116px;

    @include small-desktop() {
        padding-top: 84px;
    }
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-family: var(--nato-sans);
    &.light {
        font-family: var(--nato-sans-light);
    }
}

h1 {
    font-size: 64px;
    line-height: 93px;
}
h2 {
    font-size: 42px;
    line-height: 57px;
    @include tablet() {
        font-size: 24px;
        line-height: 33px;
    }
}
h3 {
    font-size: 36px;
    line-height: 49px;
    @include tablet() {
        font-size: 24px;
        line-height: 33px;
    }
}
h4 {
    font-size: 26px;
    line-height: 35px;
}
body,
p {
    font-size: 16px;
    line-height: 140%;
    font-weight: 300;
}

a {
    cursor: pointer;
    color: var(--brown);
    &.black-text {
        color: var(--primary-black);
        @include transition(0.5s);
        &:hover {
            text-decoration: underline;
        }
    }
}

// Structure
.page {
    .wrapper {
        padding: 0px 72px;
        max-width: $small-desktop;
        margin: auto;
        @include mobile() {
            padding: 0px 36px;
        }
    }
}

// Responsive
.hide-desktop {
    @include desktop() {
        display: none !important;
    }
}
.hide-tablet {
    @include tablet-only() {
        display: none !important;
    }
}
.hide-tablet-mobile {
    @include tablet() {
        display: none !important;
    }
}
.hide-mobile {
    @include mobile() {
        display: none !important;
    }
}

.MuiButton-root {
    min-width: 180px !important;
    border-radius: 0px !important;
    padding: 16px 8px !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    box-shadow: none !important;

    @include mobile {
        min-width: 160px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: 0.1px !important;
    }

    // Primary Button
    &.primary-btn {
        background-color: var(--white) !important;
        border: 2px solid var(--bk-green) !important;
        color: var(--bk-green) !important;
        &:hover {
            background-color: var(--bk-green) !important;
            color: var(--white) !important;
        }
    }
}
