// Nato Sans
@font-face {
    font-family: 'NatoSans Regular';
    src: url('../assets/fonts/NotoSans-Regular.ttf');
}

@font-face {
    font-family: 'NatoSans Light';
    src: url('../assets/fonts/NotoSans-Light.ttf');
}

@font-face {
    font-family: 'NatoSans Bold';
    src: url('../assets/fonts/NotoSans-Bold.ttf');
}

@font-face {
    font-family: 'Simula Book';
    src: url('../assets/fonts/Simula-Book.ttf');
}

body {
    --nato-sans: 'NatoSans Regular';
    --nato-sans-light: 'NatoSans Light';
    --nato-sans-bold: 'NatoSans Bold';
    --simula-book: 'Simula Book';
}
