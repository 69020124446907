@import '../../../scss/mixins';

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    left: 0;
    top: 0;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    .loader-container {
        position: relative;
        .logo {
            position: absolute;
            height: 90px;
            left: 50%;
            top: 50%;
            margin-top: -45px;
            margin-left: -45px;
        }
    }

    &.transparent {
        background-color: var(--brown-gradient);
    }
}
